
import { computed, defineComponent, PropType } from 'vue';
import { object, string } from 'yup';

/** Components */
import { Form, Field } from 'vee-validate';

/** Interfaces */
import { LoginI } from '@/interfaces/auth.interface';
export default defineComponent({
  name: 'ModalConfirmSms',
  emits: ['login', 'resend-sms', 'close'],
  components: {
    Form,
    Field,
  },
  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    dataForm: {
      type: Object as PropType<LoginI>,
      required: true,
    },
    sendingLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    sendingSuccess: {
      type: Boolean,
      required: false,
      default: false,
    },
    loginLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const schemaLoginForm = object({
      otp_code: string()
        .trim()
        .matches(/^[0-9]{6}$/, 'El código no es válido.')
        .max(6, 'máximo 6 caracteres.')
        .required('Por favor ingrese su código.'),
    });

    const phoneSms = computed(() => {
      return `${'*'.repeat(7)}${props.dataForm?.phone_number?.substr(-3)}`;
    });

    const login = (form: LoginI) => {
      const data: LoginI = {
        otp_code: form.otp_code,
        ...props.dataForm,
      };
      emit('login', data);
    };

    const resendSms = () => {
      emit('resend-sms');
    };

    const close = () => {
      emit('close');
    };

    return {
      close,
      phoneSms,
      login,
      resendSms,
      schemaForm: schemaLoginForm,
    };
  },
});
